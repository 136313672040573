import * as React from "react"
import { graphql, Link } from "gatsby"
import { BsArrowLeft } from "react-icons/bs"

const MessagePage = ({ data }) => {
  let isEnglish = data.context.context.nodeLocale === "en-CA"

  return (
    <div className="h-screen bg-secondary flex">
      <div className="bg-primary rounded-lg m-auto w-1-2 lg:w-1/3">
        <div className="p-12 flex flex-col items-center">
          <div className="py-2 text-center border-b border-secondary">
            <p>
              Merci de nous avoir contactés !
              <br />
              Nous vous répondrons dans les plus brefs délais.
            </p>
          </div>
          <div className="py-2 text-center">
            <p>
              Thank you for contacting us!
              <br />
              We will answer you as soon as possible.
            </p>
          </div>
          <Link
            to={isEnglish ? "/en/" : "/"}
            className="mt-6 text-center bg-secondary hover:bg-tertiary border border-transparent rounded-md py-3 px-8 font-medium text-white flex flex-row items-center "
          >
            <BsArrowLeft />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MessagePage

export const query = graphql`
  query Message($slug: String!, $nodeLocale: String!) {
    page: contentfulMessagePage(
      slug: { eq: $slug }
      node_locale: { eq: $nodeLocale }
    ) {
      title
      slug
      id
      message {
        raw
      }
    }
    context: sitePage(
      context: { nodeLocale: { eq: $nodeLocale }, slug: { eq: $slug } }
    ) {
      id
      path
      context {
        slug
        nodeLocale
        pathNoLanguagePrefix
      }
    }
  }
`
